import { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'

const DesignList = () => import('./views/DesignList.vue')
const DesignCreate = () => import('./views/DesignCreate.vue')
const DesignEdit = () => import('./views/DesignEdit.vue')
const DesignSetEdit = () => import('./views/DesignSetEdit.vue')

const CategoriesList = () => import('./views/CategoriesList.vue')
const CategoryEdit = () => import('./views/CategoryEdit.vue')
const CategoryCreate = () => import('./views/CategoryCreate.vue')


const DESIGN_PREFIX = 'cabinet:design:'
const CATEGORIES_PREFIX = 'categories:'
const p = (name: string) => DESIGN_PREFIX + name


export const DESIGN_LIST = p('list')
export const DESIGN_CREATE = p('create')
export const DESIGN_EDIT = p('edit')
export const DESIGN_SET_EDIT = p('edit-set')
export const DESIGN_CATEGORIES = p(CATEGORIES_PREFIX + 'list')
export const DESIGN_CATEGORIES_CREATE = p(CATEGORIES_PREFIX + 'create')
export const DESIGN_CATEGORIES_EDIT = p(CATEGORIES_PREFIX + 'edit')

export function createRoutes(): RouteRecordRaw[] {
  return [
    route('list/', DesignList, DESIGN_LIST),
    route(':id(\\d+)/edit/', DesignEdit, DESIGN_EDIT),
    route(':id(\\d+)/edit-set/', DesignSetEdit, DESIGN_SET_EDIT),
    route('create/', DesignCreate, DESIGN_CREATE),
    prefixRoutes('categories/', [
      route('list/', CategoriesList, DESIGN_CATEGORIES),
      route('create/', CategoryCreate, DESIGN_CATEGORIES_CREATE),
      route(':id(\\d+)/edit/', CategoryEdit, DESIGN_CATEGORIES_EDIT),
    ]),
    { path: '', redirect: { name: DESIGN_LIST } },
  ]
}
