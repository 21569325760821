<script lang="ts">
import { ref, SetupContext, defineComponent, watch } from 'vue'
import { makeProps, useAllListed, ListedControllerProps } from '@aspectus/vue-selection-controller/all'
import { ItemsMap } from '@aspectus/selection-controller'

export default defineComponent({
  name: 'AllSelectionController',
  props: makeProps(),
  emits: ['change'],
  setup(props: ListedControllerProps & Record<string, unknown>, { slots, emit }: any) {
    const controller = useAllListed(props)
    watch(controller.state.all, (newValue: any) => {
      // !NOTE: be carful if setAll(true) - controller.state.included will be empty
      emit('change', newValue ? controller.options.value.raw() : [])
    })
    watch(controller.state.included, (newValue: any): void => {
      emit('change', newValue.raw())
    })

    return () => slots.default?.(controller) || null
  },
})
</script>
