import { VNode, DefineComponent, unref, h, SetupContext, markRaw, defineAsyncComponent } from 'vue'
import { useClasses } from '@/plugins/bem/index'
import { Tag } from '@aspectus/vue-tag'
import type { ModifierValue } from '@aspectus/bem'

const UiLoading = defineAsyncComponent(() => import('./UiLoading.vue'))

interface Props {
  styling: ModifierValue
  variant: ModifierValue
  fullscreen: ModifierValue
  size: ModifierValue
  loading: boolean
  duration: undefined | string
}

function LoadingWrapper(props: Props, { slots }: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>): VNode {
  const {
    styling,
    variant,
    fullscreen,
    loading,
    size,
    duration,
  } = props

  const classes = useClasses('loading-wrapper')
  return h(
    'div', 
    {
      class: classes.root.m({ 
        styling: styling,
        variant: variant,
        fullscreen: fullscreen,
      }).s({ 
        loading: unref(loading),
      }).value

    },
    [
      slots.default ? slots.default() : '',
      h('div', { class: 'loading-wrapper__overlay'}),
      h(
        'div', 
        { class: 'loading-wrapper__indicator'}, 
        h(
          UiLoading as unknown as DefineComponent, 
          {
            size: size,
            duration: duration,
          }
        )
      )
    ]
  )
}

LoadingWrapper.props = {
  styling: { default: 'default' },
  variant: { default: 'default' },
  fullscreen: { default: false },
  loading: { default: false },
  size: { default: 'xl' },
  duration: {},
}

export default LoadingWrapper
