import { defineStore } from 'pinia';
import { ref, computed, Ref } from 'vue'
import {
  userRetrieve as receiveResource,
  userRetrieve as patchResource,
} from '@/services/accounts.service.ts';
import { User as UserClass } from './user/user';

declare global {
  interface Window {
    isAuthenticated: string | number
  }
}

// export const useAuthStore = defineStore('authStore', {
//   state: () => ({
//     userId: window.isAuthenticated,
//     loading: false,
//     user: new UserClass({}, false),
//   }),

//   getters: {
//     isAuthenticated: (state: { user: UserClass }) => state.user.isAuthenticated(),
//     isDetermined: (state: { user: UserClass }) =>  state.user.determined,
//   },

//   actions: {

//     setUser(newUser: UserClass): void {
      
//       this.user = newUser;
//     },
//     updateUser(data: { [key: string]: unknown }): void {
//       this.user = new UserClass({ ...this.user.info, ...data });
//     },
//     setLoading(value: boolean) {
//       this.loading = value;
//     },
//     initialReceive() {
//       if (this.user.determined) {
//         return null;
//       }
//       return this.lockedReceive();
//     },
//     lockedReceive(): Promise<void> | null {
//       if (this.loading) {
//         return null;
//       }

//       return this.receive(this.userId);
//     },
//     receive(id: string | number): Promise<void> {
//       this.setLoading(true);

//       return receiveResource.execute({ id })
//         .then(({ item = {}  }) => {
//           this.setUser(new UserClass(item));
//         }).catch((error: Response) => {
//           this.setUser(new UserClass({}));
//           console.warn(error);
//         }).finally(() => { this.setLoading(false); });
//     },

//     patch(payload: unknown) {
//       return patchResource.execute({}, payload)
//         .then(({ data: { item = {} } = {} }) => {
//           this.updateUser(item);
//         });
//     },
//   },

// });

export const useAuthStore = defineStore('authStore', () => {
  const userId = ref(window.isAuthenticated)

  const loading: Ref<boolean> = ref(false)
  const user = ref(new UserClass({}, false))

  const isAuthenticated = computed(() => user.value.isAuthenticated())
  const isDetermined = computed(() => user.value.determined)


  function setUser(newUser: UserClass): void {
    user.value = newUser;
  }
  function updateUser(data: { [key: string]: unknown }): void {
    user.value = new UserClass({ ...user.value.info, ...data });
  }
  function setLoading(value: boolean): void {
    loading.value = value;
  }
  function initialReceive() {
    if (user.value.determined) {
      return null;
    }
    return lockedReceive();
  }
  function lockedReceive(): Promise<void> | null {
    if (loading.value) {
      return null;
    }

    return receive(userId.value);
  }
  function receive(id: string | number): Promise<void> {
    setLoading(true);

    return receiveResource.execute({ id })
      .then(({ item = {}  }) => {
        setUser(new UserClass(item));
      }).catch((error: Response) => {
        setUser(new UserClass({}));
        console.warn(error);
      }).finally(() => { setLoading(false); });
  }

  function patch(payload: unknown) {
    return patchResource.execute({}, payload)
      .then(({ data: { item = {} } = {} }) => {
        updateUser(item);
      });
  }

  return {
    loading,
    user,
    isAuthenticated,
    isDetermined,
    setUser,
    updateUser,
    setLoading,
    initialReceive,
    lockedReceive,
    receive,
    patch,
  }

});
