type UserIdentifier = string | number
export interface UserInfo {
  id?: UserIdentifier,
  pk?: UserIdentifier,
  auth?: UserIdentifier,
  [key: string]: unknown,
}

export class UserBase {
  determined = false;

  info: UserInfo = {};

  isAuthenticated(): false | UserIdentifier {
    return this.determined && this.info && (this.info.id || this.info.pk || this.info.auth) || false;
  }
}
