import {
  createReceiver,
  createSender,
  createUpdater,
} from '../plugins/resource'

import {
  prefixAPI,
} from '../plugins/resource/api'

const MODEL = 'accounts'

export const userRetrieve = createReceiver(prefixAPI('/detail/', MODEL))
export const userLogin = createSender(prefixAPI('/login/', MODEL))
export const userLogout = createSender(prefixAPI('/logout/', MODEL))

export const userUpdate = createUpdater(prefixAPI('/update/', MODEL))

export const userPasswordChange = createSender(prefixAPI('/passord-change/', MODEL))
export const userPasswordReset = createSender(prefixAPI('/password-reset/', MODEL))
export const userPasswordCheckCode = createSender(prefixAPI('/password-reset/check-code/', MODEL))
export const userPasswordRequest = createSender(prefixAPI('/password-reset/request/', MODEL))

export const getWsToken = createReceiver(prefixAPI('/ws-token/', MODEL))
