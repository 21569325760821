import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: $setup.classes.root.m({ styling: $setup.styling, size: $setup.size, variant: $setup.variant }).s({ disabled: $setup.disabled, readonly: $setup.readonly, required: $setup.required }).value
  }, { 'aria-disabled': $setup.disabled }), [
    _createElementVNode("span", {
      class: _normalizeClass($setup.classes.body())
    }, [
      _renderSlot(_ctx.$slots, "prepend"),
      ($props.startIcon || _ctx.$slots.startIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass($setup.classes.element.m({ attachment: 'prepend' }).value)
          }, [
            _renderSlot(_ctx.$slots, "startIcon", {}, () => [
              _createVNode(_component_AppIcon, {
                name: $props.startIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent($props.innerElement), _mergeProps({
        class: $setup.classes.element.m({ primary: true }).value
      }, _ctx.$attrs, {
        name: $props.name,
        type: $setup.inputType,
        readonly: $setup.readonly,
        disabled: $setup.disabled,
        id: $props.id || $props.name,
        onInput: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('update:value', $event.target.value))),
        value: $props.value
      }), null, 16, ["class", "name", "type", "readonly", "disabled", "id", "value"])),
      ($props.endIcon || _ctx.$slots.endIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass($setup.classes.element.m({ attachment: 'append' }).value)
          }, [
            _renderSlot(_ctx.$slots, "endIcon", {}, () => [
              _createVNode(_component_AppIcon, {
                name: $props.endIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "append")
    ], 2),
    ($setup.isPasswordField)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "change-type",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.toggleType()))
        }, [
          _createVNode(_component_AppIcon, {
            name: $setup.inputType === $setup.p ? 'IVisibility': 'IVisibilityOff'
          }, null, 8, ["name"])
        ]))
      : _createCommentVNode("", true)
  ], 16))
}