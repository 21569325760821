import { route } from '@/router/utils'
import { RouteRecordRaw } from 'vue-router'

const Login = () => import('./views/Login.vue')
const PasswordReset = () => import('./views/PasswordReset.vue')
// const PasswordConfirm = () => import('./views/PasswordConfirm');
const PasswordSet = () => import('./views/PasswordSet.vue')

export const AUTH_LOGIN = 'auth:login'
export const AUTH_PASSWORD_RESET = 'auth:password-reset'
export const AUTH_PASSWORD_SET = 'auth:password-set'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route('login/', Login, AUTH_LOGIN),
    route('password/reset/', PasswordReset, AUTH_PASSWORD_RESET),
    route('password/set/:token/', PasswordSet, AUTH_PASSWORD_SET),
    // route('password/confirm/', PasswordConfirm, 'auth:password-confirm'),

    { path: '', redirect: { name: 'auth:login' } },
  ]
}
