/* eslint-disable */
export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0
}

export function getSingleParam<TParam = unknown>(params: [TParam] | Record<string, TParam>, paramName: string) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

const email = (value: unknown) => {
  if (isEmpty(value)) {
    return true
  }
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value))
}

const confirmed = (value: unknown, params: [string] | { target: string }) => {
  const target = getSingleParam(params, 'target')

  return String(value) === String(target)
}



const min = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (String(value).length >= Number(length)) {
    return String(value).length >= Number(length)
  } else {
    // return 'Enter the full phone number.'
    return false
  }
}

const max = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => max(val, { length }))
  }

  if (String(value).length <= Number(length)) {
    return String(value).length <= Number(length)
  } else {
    return 'Value must be shorter than ' + length
  }
}

export {
  email,
  confirmed,
  min,
  max,
}
