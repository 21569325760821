import { RouteRecordRaw } from 'vue-router';
import { prefixRoutes, route } from '@/router/utils';

const CategoriesList = () => import('./views/CategoriesList.vue')
const View = () => import('./views/View.vue')

const SALE_CHANNELS_PREFIX = 'cabinet:sale-channels:'
const CATEGORIES_PREFIX = 'categories:'
const p = (name: string) => SALE_CHANNELS_PREFIX + name

export const SALE_CHANNELS_VIEW = p('view')
export const SALE_CHANNELS_LIST = p('list')

export function createRoutes(): RouteRecordRaw[] {
  return [
    // route('list/', CategoriesList, SALE_CHANNELS_LIST),
    route('categories/', CategoriesList, SALE_CHANNELS_LIST),
    route(':id(\\d+)/view/', View, SALE_CHANNELS_VIEW),
  ]
}