export const {
  MAP, ALL: TOKENS,
} = window.ACCESS_TOKENS || {};

declare global {
  interface Window {
    ACCESS_TOKENS: {
      MAP?: { [key: string]: string },
      ALL?: { [key: string]: string }
    }
  }
}
