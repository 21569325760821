import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import 'vue-toast-notification/dist/theme-sugar.css';
import './styles/index.sass'

const app = createApplication({
  createApp, createRouter, createWebHistory, createPinia,
})
