import { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'

const List = () => import('./views/List.vue')
const Create = () => import('./views/Create.vue')
const Detail = () => import('./views/Detail.vue')

const PRINTS_PREFIX = 'cabinet:prints:'

const p = (name: string) => PRINTS_PREFIX + name

export const PRINTS_LIST = p('list')
export const PRINTS_DETAIL = p('detail')
export const PRINTS_CREATE = p('create')

export function createRoutes(): RouteRecordRaw[] {
  return [
    route('list/', List, PRINTS_LIST),
    route('create/', Create, PRINTS_CREATE),
    route(':id(\\d+)/detail/', Detail, PRINTS_DETAIL),
    { path: '', redirect: { name: PRINTS_LIST } },
  ]
}
