import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "observer",
  ref: "observerTarget"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes.root.m({ styling: $setup.styling, size: $setup.size, variant: $setup.variant }).s({ disabled: $setup.disabled, readonly: $setup.readonly, invalid: $setup.invalid, required: $setup.required }).value)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass($setup.classes.body())
    }, [
      _renderSlot(_ctx.$slots, "prepend"),
      ($props.startIcon || _ctx.$slots.startIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass($setup.classes.element.m({ attachment: 'prepend' }).value)
          }, [
            _renderSlot(_ctx.$slots, "startIcon", {}, () => [
              _createVNode(_component_AppIcon, {
                name: $props.startIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      _createVNode($setup["VueMultiselect"], _mergeProps({
        class: ["element", $setup.classes.element.m({ primary: true }).value]
      }, $props.showLabels ? { ..._ctx.$attrs, label: $props.labelBy, 'track-by': $props.trackBy } : _ctx.$attrs, {
        multiple: $props.multiple,
        options: $setup.internalOptions,
        modelValue: $setup.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($setup.model = $event)),
        placeholder: _ctx.$attrs.taggable ? _ctx.$t('Search or add new') : _ctx.$attrs.placeholder == undefined ? _ctx.$attrs.inputLabel : _ctx.$attrs.placeholder,
        loading: _ctx.$attrs.loading || $setup.internalLoading,
        onTag: $setup.addTag,
        "internal-search": $props.internalSearch,
        onSearchChange: $setup.debounceSearch,
        onRemove: $setup.onChange,
        onInput: $setup.onChange
      }), _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (_, name) => {
          return {
            name: name,
            fn: _withCtx((bound) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(bound || {})))
            ])
          }
        }),
        ($props.resource && !_ctx.$slots.afterList)
          ? {
              name: "afterList",
              fn: _withCtx(() => [
                _createElementVNode("li", null, [
                  (!$setup.internalLoading && $setup.pagination.total > $setup.pagination.offset + $setup.pagination.limit)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
                    : _createCommentVNode("", true)
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1040, ["class", "multiple", "options", "modelValue", "placeholder", "loading", "internal-search", "onSearchChange"]),
      ($props.endIcon || _ctx.$slots.endIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass($setup.classes.element.m({ attachment: 'append' }).value)
          }, [
            _renderSlot(_ctx.$slots, "endIcon", {}, () => [
              _createVNode(_component_AppIcon, {
                name: $props.endIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "append")
    ], 2)
  ], 2))
}