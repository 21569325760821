<script lang="ts">
import { defineComponent, SetupContext, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../store'

export default defineComponent({
  setup(props: unknown, { slots }: SetupContext) {
    onBeforeMount(async () => {
      const authStore = useAuthStore()
      const { receive } = authStore
      const router = useRouter()
      if (window.isAuthenticated) {
        await receive(window.isAuthenticated)
      } else {
        router.push({
          name: 'auth:login',
        })
      }
    })
    return () => slots.default?.()
  },
})
</script>
