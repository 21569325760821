import { RouteRecordRaw } from 'vue-router';
import { prefixRoutes, route } from '@/router/utils'

const CategoriesList = () => import('./views/CategoriesList.vue')
const List = () => import('./views/List.vue')
const PreviewList = () => import('./views/PreviewList.vue')
const Create = () => import('./views/Create.vue')
const Edit = () => import('./views/Edit.vue')

const PRODUCTS_PREFIX = 'cabinet:products:'
const CATEGORIES_PREFIX = 'categories:'
const p = (name: string) => PRODUCTS_PREFIX + name

export const PRODUCTS_LIST = p('list')
export const PRODUCTS_PREVIEW_LIST = p('preview-list')
export const PRODUCTS_CREATE = p('create')
export const PRODUCTS_EDIT = p('edit')
export const PRODUCTS_CATEGORIES = p(CATEGORIES_PREFIX + 'list')

export function createRoutes(): RouteRecordRaw[] {
  return [
    route('list/', List, PRODUCTS_LIST),
    route('preview-list/', PreviewList, PRODUCTS_PREVIEW_LIST),
    route(':id(\\d+)/edit/', Edit, PRODUCTS_EDIT),
    route('create/', Create, PRODUCTS_CREATE),
    prefixRoutes('categories/', [
      route('list/', CategoriesList, PRODUCTS_CATEGORIES),
    ]),
    { path: '', redirect: { name: PRODUCTS_LIST } },
  ]
}
