import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent($setup.disabled && 'a' == $props.tag ? 'div' : $props.tag), _mergeProps({
    class: $setup.classes.root.m({ styling: $setup.styling, size: $setup.size, view: $setup.view, variant: $setup.variant, appearance: $setup.appearance }).s({ disabled: $setup.disabled, readonly: $setup.readonly, invalid: $setup.invalid }).value
  }, { 'aria-disabled': $setup.disabled, 'readonly': $setup.readonly, 'disabled': $setup.disabled, ..._ctx.$attrs }), {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass($setup.classes.body())
      }, [
        _renderSlot(_ctx.$slots, "prepend"),
        ($props.startIcon || _ctx.$slots.startIcon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass($setup.classes.element.m({ attachment: 'prepend' }).value)
            }, [
              _renderSlot(_ctx.$slots, "startIcon", {}, () => [
                _createVNode(_component_AppIcon, {
                  name: $props.startIcon,
                  size: $props.iconSize,
                  class: _normalizeClass($props.iconClass)
                }, null, 8, ["name", "size", "class"])
              ])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass($setup.classes.element.m({ primary: true }).value)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        ($props.endIcon || _ctx.$slots.endIcon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass($setup.classes.element.m({ attachment: 'append' }).value)
            }, [
              _renderSlot(_ctx.$slots, "endIcon", {}, () => [
                _createVNode(_component_AppIcon, {
                  name: $props.endIcon,
                  size: $props.iconSize,
                  class: _normalizeClass($props.iconClass)
                }, null, 8, ["name", "size", "class"])
              ])
            ], 2))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "append")
      ], 2)
    ]),
    _: 3
  }, 16, ["class"]))
}