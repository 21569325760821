import {
  RouterView,
  RouteRecordRaw,
} from "vue-router";
import { DefineComponent } from 'vue'

const mergeOptions = (
  result: RouteRecordRaw,
  options: null | Record<string, unknown> = null
): RouteRecordRaw => {
  return null !== options ? Object.assign(result, options) : result;
}


// export const Namespace = defineComponent({
//   name: "router-namespace",
//   setup(
//     props: unknown,
//     context: SetupContext,
//   ) {
//     const attrs = useAttrs();
//     const router = useRouter();
//     const route = useRoute();

//     return () =>
//       h(RouterView, null, {
//         default: () =>
//           context.slots.default
//             ? context.slots.default({
//               key: route?.meta?.key || route.path,
//               id: route?.meta?.key || route.path,
//               attrs: { ...attrs },

//             })
//             : h("slot"),
//       });
//   },
// });

// export const ResolvedNamespace = Promise.resolve(Namespace);
// export const ImportedNamespace = (): typeof ResolvedNamespace => ResolvedNamespace;

export function route(
  path: string,
  component: (() => Promise<typeof import("*.vue")>) | DefineComponent | never,
  name: string | symbol | undefined,
  options: null | Record<string, unknown> = null
): RouteRecordRaw {
  return mergeOptions({ path, component, name }, options);
}

export function prefixRoutes(
  path: string,
  children: RouteRecordRaw[],
  options: null | Record<string, unknown> = null
): RouteRecordRaw {
  return mergeOptions(
    { path, component: RouterView, children },
    options
  );
}
