import { App } from 'vue'
import { toBeAuthenticated } from '@/modules/users/permissions'
import { accessMeta } from '@aspectus/vue-router-permissions'
import { route, prefixRoutes } from '@/router/utils'
import { optionsT } from '@/router'
import { RouteRecordRaw } from 'vue-router'
import type { RouteLocation } from 'vue-router'

import {
  createRoutes as createDesignRoutes,
  DESIGN_LIST,
} from './modules/design/router'
import { createRoutes as createProductsRoutes } from './modules/products/router'
import { createRoutes as createSaleChannelsRoutes } from './modules/sale-channels/router'
import { createRoutes as createPrintsRoutes } from './modules/prints/router'

// import Comp from './views/Cabinet.vue'

const Cabinet = () => import('./views/Cabinet.vue')
const CabinetInstaller = () => import('./components')

// function routeInstaller(
//   View: any,
//   // View: () => Promise<typeof import("*.vue")>,
//   Installer: () => Promise<any>,
//   app: App<Element>
// ) {
//   return (): Promise<any> => Promise.all([View(), Installer()])
//     .then((result: [any, { default: Plugin }]): DefineComponent => {
//       const ViewComponent = result[0];
//       const installer = result[1];
//       app.use(installer.default);
//       app.component(ViewComponent.default.__name, ViewComponent.default);

//       return ViewComponent.default;
//     });
// }
function installer(
  View: () => Promise<any>,
  Installer: () => Promise<any>,
  app: App<Element>
) {
  return function () {
    return Promise.all([View(), Installer()]).then(function (result) {
      const ViewComponent = result[0]
      const installer = result[1]
      app.use(installer.default || installer.install || installer)
      return ViewComponent
    })
  }
}

export function createRoutes(options: optionsT): RouteRecordRaw[] {
  const { app } = options
  return [
    route('', installer(Cabinet, CabinetInstaller, app), 'cabinet', {
      children: [
        prefixRoutes('design/', createDesignRoutes()),
        prefixRoutes('products/', createProductsRoutes()),
        prefixRoutes('sale-channels/', createSaleChannelsRoutes()),
        prefixRoutes('prints/', createPrintsRoutes()),
        {
          path: '',
          redirect: (to: RouteLocation) => to.fullPath + 'design/',
        },
      ],
      meta: accessMeta(toBeAuthenticated, { name: 'auth:login' }, options),
      redirect: { name: DESIGN_LIST },
    }),
  ]
}
