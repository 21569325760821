import { App, defineAsyncComponent } from 'vue'

const iconsComp = require.context('./', true, /\.vue$/)

const install = (app: App<Element>): void => {
  for (const comp of iconsComp.keys()) {
    const name = comp.replace(/^\.\//, '').replace(/\.\w+$/, '')
    app.component(`I${name}`, defineAsyncComponent(() => import(`./${name}.vue`)))
  }
}

export default {
  install,
}
