import { App } from 'vue'
import VueTag from '@aspectus/vue-tag'
import VueGrid from '@aspectus/vue-grid'
import FloatingVue from 'floating-vue'

export default {
  install(app: App<Element>): void {
    app
      .use(VueGrid)
      .use(VueTag)
      .use(FloatingVue)
  },
}
