interface UserInfo {
  info: {
    userType: string
  }
  isAuthenticated: () => boolean
}

import { path, curry } from 'ramda'
// import { Or } from '@aspectus/permissions';
import { accessMeta } from '@aspectus/vue-router-permissions'
import { checkAuth, UserCheckerPermission } from '../auth/permissions'
import { MAP } from './accessTokens'

const Route403 = () => import('@/components/Common/403.vue')

const userStateGetter = path(['state', 'value', 'authStore'])
export type UserStateGetter = typeof path

export function checkUser(
  checker: unknown,
  config = { timeout: 5000, tick: 10 }
  // ...args: { [key: string]: unknown }[]
): UserCheckerPermission {
  return checkAuth(
    checker,
    Object.assign({ getter: userStateGetter }, config)
    // , ...args
  )
}

// export const isSuperuser = user => user.info.isSuperuser;
export const isType = curry(
  (type: string, user: UserInfo) => user.info.userType === type
)
export const isPermitted = curry(
  (permission: number, user: UserInfo) =>
    !!(MAP && MAP[user.info.userType] && MAP[user.info.userType][permission])
)

export const toBe = (type: string): UserCheckerPermission =>
  checkUser(isType(type))
export const toBeNotAuthenticated = checkUser(
  (user: UserInfo) => !user.isAuthenticated()
)
export const toBeAuthenticated = checkUser((user: UserInfo) => {
  return user.isAuthenticated()
})

export const toHasOwnPermission = (permission: string): UserCheckerPermission =>
  checkUser(isPermitted(permission))
// export const toHasPermission = permission => new Or(
//   // toBeSuperuser,
//   toHasStaffPermission(permission)
// );

export const accessPermission = (
  permission: string,
  options: { [key: string]: unknown }
): { [key: string]: unknown } =>
  accessMeta(
    toHasOwnPermission(permission),
    { path: '/permission-denied/', component: Route403 },
    options
  )
