import { prefixLanguage } from '../utils/urls'

import { RouteRecordRaw } from 'vue-router'
import { accessMeta } from '@aspectus/vue-router-permissions'
// import { createRoutes as CommonRoutes } from '@/router/Common/routes'
// import { createRoutes as TraderRoutes } from '@/router/Trader/routes'
import { route, prefixRoutes } from '@/router/utils'
import { optionsT } from '@/router'
import { toBeAuthenticated } from '../modules/users/permissions'
import { createRoutes as createAuthRoutes } from '@/sections/auth/router'
import { createRoutes as createCabinetRoutes } from '@/sections/cabinet/router'

// const ViewComponent = () => import('@components/Profiles/Views/Profile.vue')
export const NO_ALLOWED = 'no-allowed'
export const NOT_FOUND = 'not-found'
const NoAllowed = () => import('@/components/Common/403.vue')
const NotFound = () => import('@/components/Common/404.vue')

export default function createInitialRoutes(
  options: optionsT
): RouteRecordRaw[] {
  return [
    // route(`/`, Route402, 'base', {
    //   children: [
    //     prefixRoutes('err/', [
    //       prefixRoutes('my/', [
    //         route('404/', NotFound, '404', {
    //           meta: {
    //             ...accessMeta(toBeAuthenticated, { path: '/login/', component: Route403 }, options),
    //           },
    //         }),
    //       ]),
    //     ]
    //     )
    //   ],
    //   meta: accessMeta(toBeAuthenticated, { path: '/login/' }, options),
    // }),
    prefixRoutes(prefixLanguage('/cabinet/'), createCabinetRoutes(options)),
    prefixRoutes(prefixLanguage('/auth/'), createAuthRoutes()),
    route('/403/', NoAllowed, NO_ALLOWED),
    route('/404/', NotFound, NOT_FOUND),
    { path: prefixLanguage(''), redirect: { name: 'cabinet' } },
    { path: prefixLanguage('/'), redirect: { name: 'cabinet' } },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
    // useRoute(BASE_URL, ViewComponent, 'profile', {
    // redirect: { name: COMPANIES_ROUTE },
    // children: [
    //   ...CommonRoutes(options),
    //   ...TraderRoutes(options),
    // ],
    // }),
  ]
}
