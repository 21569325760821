import { App } from 'vue'

import {
  Form,
  Field,
  // FieldArray,
  ErrorMessage,
  defineRule,
  configure,
} from './index'

import { url, length, numeric } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import {
  isNullOrUndefined,
  isEmptyArray,
  confirmed,
  email,
  min,
  max,
} from './rules-helper'
defineRule('numeric', numeric)

export type FormValidator = InstanceType<typeof Form>


export default {
  async install(app: App<Element>): Promise<void> {
    const { $t } = app.config.globalProperties

    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    // app.component('VeeFieldArray', FieldArray)
    app.component('VeeError', ErrorMessage)

    defineRule('required', (value: string | unknown[] | boolean) => {
      if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
        return $t('This field is required')
      }
      return true
    })
    defineRule('password', (value: string) => {
      if (!value || !value.length) {
        return $t('This field is required')
      }
      if (7 > value.length) return $t('Min length 8')

      const PASSWORD_REG = /^(?=.*?[A-ZА-Я])(?=.*?[a-zа-я])(?=.*?[0-9]).*$/;
      if (!PASSWORD_REG.test(value)) {
        return $t('The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number.')
      }

      return true
    })
    defineRule('email', (value: unknown | unknown[]) => {
      return email(value) || $t('This field must be a valid email')
    })
    defineRule('confirmed', (value: unknown, params: [string] | { target: string }) => {
      return confirmed(value, params) || $t('Passwords must match')
    })

    defineRule(
      'min',
      (
        value: unknown,
        params: [string | number] | { length: string | number }
      ): boolean | string =>
        max(value, params) || $t('Value must be longer than ') + length
    )

    defineRule(
      'max',
      (
        value: unknown,
        params: [string | number] | { length: string | number }
      ): boolean | string =>
        min(value, params) || $t('Value must be shorter than ') + length
    )

    defineRule('length', length)
    defineRule('url', url)


    // const { language: lang } = window
    // await import(`@vee-validate/i18n/dist/locale/${lang}.json`)
    //   .then(dictionary => {
    //     configure({
    //       generateMessage: localize({ [lang]: dictionary }) as any,
    //     })
    //     setLocale(lang)
    //   })
    //   .catch(() => {
    //     console.warn(`Vee-Validate: Locale - ${lang} was not found!`)
    //   })
  },
}
