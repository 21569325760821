import { App, defineAsyncComponent } from 'vue'
import Controls from './Controls'
import General from './General'

const ModalTrigger = defineAsyncComponent(() => import('./Modals/ModalTrigger.vue'))

const install = (app: App<Element>): void => {
  app
    .component('ModalTrigger', ModalTrigger)

    .use(Controls)
    .use(General)
}

export default { install }
