import { App } from 'vue'

import UiElements from './UiElements'


export default {
  install(app: App<Element>): void {
    UiElements.install(app)
  }
}
