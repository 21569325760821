import { h, App, SetupContext, DefineComponent, FunctionalComponent, VNode } from 'vue'

import CButton from './RootElement.vue'
import Label from './Label.vue'
import Descriptor from './Descriptor.vue'
import InputElement from './RootInput.vue'
import Select from './Select.vue'
import Checker from './Checker'

const wrp = (tag: string, blockName: string, root: typeof InputElement | typeof Select = InputElement) => (
  props: Record<string, unknown>,
  ctx: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
): VNode => {
  return h(root, { ...props, ...ctx.attrs, innerElement: tag, blockName }, ctx.slots)
}
type Context = { slots: unknown, emit: unknown, attrs: Record<string, unknown> }
function hoc(WrappedComponent: any, params: Record<string, unknown>) {
  return h(WrappedComponent, {
    ...params,
    })
}

export const CInput = wrp('input', 'control-input')
export const CTextarea = wrp('textarea', 'control-textarea',)
export const CSelect = wrp('vue-multiselect', 'control-select', Select)

export const CCheckbox = hoc(Checker, { b: 'control-checkbox' })
export const CRadio = hoc(Checker, { b: 'control-radio' })

const dt = (tag: string, root = Descriptor) => (
  props: Record<string, unknown>,
  ctx: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
): VNode => h(root, { ...props, ...ctx.attrs, component: tag }, ctx.slots)

const DInput = dt('ControlInput')
const DTextarea = dt('ControlTextarea')
const DSelect = dt('ControlSelect')


export default {
  install(app: App<Element>): void {
    app
      .component('ControlButton', CButton)
      .component('ControlField', Descriptor)
      .component('ControlLabel', Label)
      .component('ControlInput', CInput)
      .component('ControlTextarea', CTextarea)
      .component('ControlSelect', CSelect)
      .component('ControlCheckbox', CCheckbox)
      .component('ControlRadio', CRadio)
      .component('DInput', DInput)
      .component('DTextarea', DTextarea)
      .component('DSelect', DSelect)
  }
}
