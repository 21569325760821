import { App, defineAsyncComponent } from 'vue'
import SelectionController from './AllSelectionController.vue'
import LoadingWrapper from './LoadingWrapper'
import Icons from './Icons'


const TableItemImages = defineAsyncComponent(() => import('./TableItemImages.vue'))
const Logo = defineAsyncComponent(() => import('./Logo.vue'))
const AppIcon = defineAsyncComponent(() => import('./AppIcon.vue'))

const install = (app: App<Element>): void => {
  app
    .component('Logo', Logo)
    .component('AppIcon', AppIcon)
    .component('VueAllSelectionController', SelectionController)
    .component('LoadingWrapper', LoadingWrapper)
    .component('TableItemImages', TableItemImages)

    
  Icons.install(app)
}

export default { install }
